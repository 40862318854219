import {Injectable} from '@angular/core';
import {DeviceMessagingConnection, RemoteMessage, WebsocketService} from "./websocket.service";
import {filter, flatMap, last, map, take, takeUntil, takeWhile} from "rxjs/operators";
import {AsyncSubject, from, MonoTypeOperatorFunction, Observable, of, Subject, throwError} from "rxjs";

export const RTC_STREAM_TYPE_NONE = 0;
export const RTC_STREAM_TYPE_VIDEO_SCREEN_SHARE = 1 << 0;
export const RTC_STREAM_TYPE_VIDEO_CAMERA = 1 << 1;
export const RTC_STREAM_TYPE_AUDIO = 1 << 2;
export const RTC_STREAM_TYPE_TERMINAL = 1 << 3;

@Injectable({
    providedIn: 'root'
})
export class DeviceRemoteControlService {

    static extractStreamTypes(arg: any): number {
        let flags = 0;
        if (arg) {
            flags = parseInt(arg, 10) || flags;
        }
        let outFlags = 0;
        if ((flags & RTC_STREAM_TYPE_VIDEO_SCREEN_SHARE) === RTC_STREAM_TYPE_VIDEO_SCREEN_SHARE) {
            outFlags |= RTC_STREAM_TYPE_VIDEO_SCREEN_SHARE
        } else if ((flags & RTC_STREAM_TYPE_VIDEO_CAMERA) === RTC_STREAM_TYPE_VIDEO_CAMERA) {
            outFlags |= RTC_STREAM_TYPE_VIDEO_CAMERA
        }
        if ((flags & RTC_STREAM_TYPE_AUDIO) === RTC_STREAM_TYPE_AUDIO) {
            outFlags |= RTC_STREAM_TYPE_AUDIO;
        }
        return outFlags
    }

    constructor(
        private svcWs: WebsocketService,
    ) {
    }

    openRtcSession(deviceId: string, streamType: number = RTC_STREAM_TYPE_NONE): Observable<RtcDeviceSession> {
        const defaultErrorMessage = 'Error establishing connection';

        streamType = DeviceRemoteControlService.extractStreamTypes(streamType)

        return this.svcWs.sendRequireConfirmation(new RemoteMessage({
            type: 'remote_control_device',
            data: {
                device_id: deviceId,
                stream_type: streamType,
            }
        }), 5000).pipe(
            flatMap(value => {
                if (!value) {
                    return throwError('Connection timeout')
                }
                if (!value.getDataBoolean('success', false)) {
                    return throwError(value.getDataString('message', defaultErrorMessage))
                } else {
                    return this.svcWs.onPeerDeviceConnected(deviceId).pipe(
                        flatMap(wsConn => {
                            if (!wsConn) {
                                return throwError(defaultErrorMessage)
                            }
                            return of(new RtcDeviceSession(deviceId, wsConn))
                        })
                    )
                }
            })
        )
    }


}

/*
    android.createOffer()
    then(androidOffer => android.setLocalDescription(androidOffer))
    then(() => web.setRemoteDescription(android.localDescription))
    then(() => web.createAnswer())
    then(webAnswer => web.setLocalDescription(webAnswer))
    then(() => android.setRemoteDescription(web.localDescription))

 */


export enum StreamType {

}

export enum RtcSessionCloseReason {
    Unknown,
    Normal,
    SetRemoteDescriptionError,
    IceCandidateError,
}

interface DisplayInfo {
    width: number;
    height: number;
    isLandscape: boolean;
}

const DEFAULT_DISPLAY_SIZE_10_INCH: DisplayInfo = {
    width: 1280,
    height: 800,
    isLandscape: true,
};

const DEFAULT_DISPLAY_SIZE_15_INCH: DisplayInfo = {
    width: 1980,
    height: 1080,
    isLandscape: true,
};

export class RtcDeviceSession {


    private conn: RTCPeerConnection;
    private closeSubject = new AsyncSubject<RtcSessionCloseReason>();
    private streamAvailableSub = new Subject<MediaStream[]>();
    private connectionStateChangedSub = new Subject<RTCPeerConnectionState>();
    private isClosed = false;
    private messageSubject = new Subject<RemoteMessage>();
    private _terminalSession: ShellTerminalSession;

    displayInfo: DisplayInfo;

    get terminalSession(): ShellTerminalSession {
        if (this._terminalSession && this._terminalSession.isDisposed) {
            this._terminalSession = null
        }
        if (!this._terminalSession) {
            this._terminalSession = new ShellTerminalSession(this.messagingConn)
        }
        return this._terminalSession
    }

    connectionState: RTCPeerConnectionState = 'new';

    get isConnected(): boolean {
        return this.connectionState === 'connected';
    }

    onConnected(): Observable<RtcDeviceSession> {
        if (this.isConnected) {
            return of(this)
        }
        return this.onConnectionStateChanged().pipe(
            filter(value => value === 'connected') as any,
            map(value => this),
            take(1)
        )
    }

    constructor(private deviceId: string, public messagingConn: DeviceMessagingConnection) {

        switch (deviceId) {
            case '5ff658dee6610473':
                this.displayInfo = DEFAULT_DISPLAY_SIZE_15_INCH;
                break;
            default:
                this.displayInfo = DEFAULT_DISPLAY_SIZE_10_INCH;
                break
        }

        console.warn('in constructor', messagingConn)

        const sub = messagingConn.onMessage().pipe(
            this.takeUntilClose() as any
        ).subscribe((value: RemoteMessage) => {
            this.handleRemoteMessage(value)
        });
    }

    onClose(): Observable<RtcSessionCloseReason> {
        return this.closeSubject.asObservable()
    }

    onStreamsAvailable(): Observable<MediaStream[]> {
        return this.streamAvailableSub.asObservable()
    }

    onConnectionStateChanged(): Observable<RTCPeerConnectionState> {
        return this.connectionStateChangedSub.asObservable()
    }

    selectStream(type: number): Observable<boolean> {
        return this.messagingConn.sendMessageIndirect("rtc/streamSelect", {
            stream_type: type
        })
    }

    private handleRemoteMessage(msg: RemoteMessage): void {
        if (this.isClosed) {
            return
        }
        switch (msg.type) {
            case 'rtc/init':
                if (this.conn) {
                    this.conn.close()
                }
                if (msg.data && Array.isArray(msg.data['ice_servers'])) {
                    console.warn(' rtc/init msg.data', msg.data)
                    this.conn = this.createRtcPeerConnection(
                        msg.data['ice_servers'] as { url: string, credential?: string, username?: string }[],
                        msg.data as RemoteScreenInfo,
                    )
                }
                break;

            case 'rtc/setRemoteDescription':
                from(
                    this.conn.setRemoteDescription(new RTCSessionDescription({
                        sdp: msg.data['sdp'],
                        type: msg.data['type']
                    }))
                ).pipe(
                    flatMap(_ => {
                        return from(this.conn.createAnswer())
                    }),
                    flatMap((originalSdp: RTCSessionDescriptionInit) => {
                        return from(this.conn.setLocalDescription(originalSdp)).pipe(_ => {
                            return of(originalSdp)
                        })
                    }),
                    flatMap((value: RTCSessionDescriptionInit) => {
                        return this.messagingConn.sendMessageIndirect('rtc/setRemoteDescription', {
                            success: true,
                            sdp: value.sdp,
                            type: value.type
                        })
                    }),
                    this.takeUntilClose(),
                ).subscribe(value => {
                }, error1 => {
                    console.error('SetRemoteDescriptionError', error1);
                    this.close(RtcSessionCloseReason.SetRemoteDescriptionError)
                });
                break;

            case 'rtc/addIceCandidate':
                from(
                    this.conn.addIceCandidate(new RTCIceCandidate({
                        sdpMLineIndex: msg.data['label'],
                        sdpMid: msg.data['id'],
                        candidate: msg.data['candidate']
                    }))
                ).pipe(
                    this.takeUntilClose() as any
                ).subscribe(value => {
                }, error1 => {
                    this.close(RtcSessionCloseReason.IceCandidateError)
                });
                break;
            default:
                this.messageSubject.next(msg);
        }

    }

    public onRemoteMessage(): Observable<RemoteMessage> {
        return this.messageSubject.asObservable()
    }

    private takeUntilClose<T>(): MonoTypeOperatorFunction<T> {
        return takeUntil(this.closeSubject)
    }

    private createRtcPeerConnection(
        iceServers: {
            url: string,
            credential?: string,
            username?: string
        }[],
        screenInfo: RemoteScreenInfo,
    ): RTCPeerConnection {
        let displayWidth = screenInfo.display_width
        let displayHeight = screenInfo.display_height
        const isLandscape = ![1,3].includes(screenInfo?.dm_orientation)
        
        console.warn('createRtcPeerConnection', screenInfo)
        if (!isNaN(displayWidth) && !isNaN(displayHeight) && displayWidth >= 0 && displayWidth > 0) {
            if (displayHeight == 1128 && displayWidth == 1920) {
                // needed for some 27'' devices
                displayHeight = 1080
            }
            
            this.displayInfo = {
                width: displayWidth,
                height: displayHeight,
                isLandscape: isLandscape,
            };
            console.warn('displayInfo',this.displayInfo, isLandscape)
        }

        const conn = new RTCPeerConnection({
            iceServers: iceServers.map(value => {
                const x: RTCIceServer = {urls: value.url};
                if (value.credential && value.username) {
                    x.credential = value.credential;
                    x.username = value.username;
                }
                return x
            })
        });

        conn.onicecandidate = ev => {
            if (ev.candidate && this.conn === conn) {
                this.messagingConn.sendMessageIndirect('rtc/addIceCandidate', {
                    label: ev.candidate.sdpMLineIndex,
                    id: ev.candidate.sdpMid,
                    candidate: ev.candidate.candidate
                }).subscribe()

            }
        };

        conn.onconnectionstatechange = ev => {
            this.connectionState = conn.connectionState;
            this.connectionStateChangedSub.next(conn.connectionState);
        };

        conn.onicecandidateerror = ev => {
            console.error('onicecandidateerror', ev)
        };

        const streams: MediaStream[] = [];
        conn.ondatachannel = ev => {
            if (ev.channel.label === this.messagingConn.sessionId) {
                this.messagingConn.setDataChannel(ev.channel)
            }
        };

        conn.oniceconnectionstatechange = ev => {
            switch ((ev.srcElement || ev.target)['iceConnectionState']) {
                case 'disconnected':
                    break;
            }

            if (conn.iceConnectionState === 'connected' && streams.length) {
                this.streamAvailableSub.next(streams);
                streams.length = 0
            }

        };

        conn.onnegotiationneeded = ev => {
            console.error('onnegotiationneeded', ev)
        };

        conn.ontrack = ev => {
            streams.length = 0;
            for (const s of ev.streams) {
                streams.push(s)
            }
            if (conn.iceConnectionState === 'connected') {
                this.streamAvailableSub.next(streams);
                streams.length = 0
            }
        };

        return conn
    }

    close(reason = RtcSessionCloseReason.Normal): void {
        if (this.isClosed) {
            return
        }
        this.isClosed = true;

        this.closeSubject.next(reason);
        this.closeSubject.complete();
        this.streamAvailableSub.complete();
        this.connectionStateChangedSub.complete();
        this.messageSubject.complete();

        if (this.conn) {
            this.conn.close()
        }
    }

}

export interface ShellCommandOutput {
    cmd_id: number
    status_code: number;
    cmd_session_id: string;
    stdout: string;
    stderr: string;
    path: string;
    counter: number;
}

interface ShellCommandInput {
    cmd_id: number;
    cmd_session_id?: string;
    cmd_args?: string
}


export enum ShellCommandStatusCodes {
    QUEUED = -1,
    RUNNING = 0,
    OK = 1,
    WATCHDOG_EXIT = 2,
    SHELL_DIED = 3,
    SHELL_DISPOSED = 4,
    EXEC_FAILED = 5,
    VALIDATION_FAILED = 6,
}

export class ShellTerminalSession {

    private cmdIdCounter = 0;
    private cmdSessionId: string = null;
    private _isDisposed = false;

    constructor(private messagingConn: DeviceMessagingConnection) {

    }

    get isDisposed(): boolean {
        return this._isDisposed;
    }

    open(): Observable<ShellCommandOutput> {
        return this.sendWaitForReply('shell/start').pipe(
            last(null, {status_code: ShellCommandStatusCodes.EXEC_FAILED} as ShellCommandOutput)
        )
    }

    exec(cmd: string): Observable<ShellCommandOutput> {
        return this.sendWaitForReply('shell/run', cmd)
    }

    reset(): Observable<ShellCommandOutput> {
        return this.sendWaitForReply('shell/cancel')
    }

    dispose(): void {
        this._isDisposed = true;
        this.sendWaitForReply('shell/dispose').subscribe()
    }

    private sendWaitForReply(cmdType: string, args?: string): Observable<ShellCommandOutput> {
        this.cmdIdCounter += 1;
        const input: ShellCommandInput = {
            cmd_id: this.cmdIdCounter
        };
        if (this.cmdSessionId) {
            input.cmd_session_id = this.cmdSessionId
        }
        if (args) {
            input.cmd_args = args
        }
        const sentMessageId = this.cmdIdCounter;
        return new Observable<ShellCommandOutput>(subscriber => {
            const source = this.messagingConn.sendMessageDirect(cmdType, input).pipe(
                flatMap(sent => {
                    if (sent) {
                        return this.messagingConn.onMessage().pipe(
                            filter((msg: RemoteMessage) => {
                                if (msg.type === 'shell/reply') {
                                    return true
                                }
                                return false
                            }) as any,

                            map((msg: RemoteMessage) => {
                                return msg.data['result'] as ShellCommandOutput
                            }),
                            filter((received: ShellCommandOutput) => {
                                if (!this.cmdSessionId) {
                                    this.cmdSessionId = received.cmd_session_id
                                }
                                if ((sentMessageId === received.cmd_id) || (this.cmdSessionId !== received.cmd_session_id)) {
                                    return true
                                }
                                return false
                            }) as any,
                            takeWhile((received: ShellCommandOutput) => {

                                if (sentMessageId !== received.cmd_id) {
                                    return false
                                }
                                if (this.cmdSessionId !== received.cmd_session_id) {
                                    return false
                                }
                                return true
                            }) as any,

                            takeWhile((received: ShellCommandOutput) => {
                                if (!isNaN(received.status_code) && received.status_code > ShellCommandStatusCodes.RUNNING) {
                                    return false
                                }
                                return true
                            }, true) as any
                        )
                    } else {
                        return of({
                            path: null,
                            status_code: ShellCommandStatusCodes.EXEC_FAILED,
                            stdout: null,
                            stderr: "error sending command",
                            cmd_session_id: input.cmd_session_id,
                            cmd_id: input.cmd_id,
                            counter: 0,
                        })
                    }
                })
            ) as Observable<ShellCommandOutput>;


            subscriber.next({
                path: null,
                status_code: ShellCommandStatusCodes.QUEUED,
                stdout: null,
                stderr: null,
                cmd_session_id: input.cmd_session_id,
                cmd_id: input.cmd_id,
                counter: 0,
            });

            source.subscribe(value => {
                subscriber.next(value)
            }, error1 => {
                subscriber.error(error1)
            }, () => {
                subscriber.complete()
            })
        })
    }

}

interface RemoteScreenInfo {
    display_width?: number // screen capturer display width
    display_height?: number // screen capturer display height
    dm_width?: number // display metrics width
    dm_height?: number // display metrics height
    dm_orientation?: number // display metrics orientation
}
