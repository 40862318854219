<div app-looma-page pageTitle="Remote Control" pageIcon="import_export"  [headerSideTpl]="headerSideTpl" >
    <div style="height:100%; background: #1E2129" fxLayout="column" fxLayoutAlign="center center">
        <mat-progress-spinner  style="display: none" mode="indeterminate" *ngIf="isLoading"></mat-progress-spinner>
        <h2  style="display: none">Couldn't connect to device</h2>

        <app-shell-terminal (created)="onTerminalOpened($event)" (destroyed)="onTerminalDisposed($event)" style="position: absolute; width:100%; height:100%; margin: 50px;" *ngIf="viewSelection.viewType == 'terminal'"></app-shell-terminal>

        <div #videoPlayerWrap style="position: relative; width:100%; height:100%; margin: 20px;" [hidden]="!['camera', 'screen'].includes(viewSelection.viewType)">
            <video (loadedmetadata)="onVideoMetadataLoaded($event)" (resize)="onVideoResize($event)" style="position:absolute; display: none; width:100%; height:100%"  #videoPlayer controls="true">
            </video>
            <canvas #videoGestureOverlay style="position:absolute;width:100px;height:100%; background:rgba(255,0,0,0)" [style.border]="isVideoViewFocused ? '5px solid red' : 'none'"
                    (wheel)="handleScrollEvent($event)"
                    (mousemove)="handleMouseEvent($event)"
                    (mousedown)="handleMouseEvent($event)"
                    (mouseleave)="handleMouseEvent($event)"
                    (mouseup)="handleMouseEvent($event) ">
            </canvas>
        </div>



    </div>
</div>

<ng-template #headerSideTpl >
    <p class="control_label">View </p>
    <mat-form-field style="width: 100px">
        <mat-select [(ngModel)]="viewSelection.viewType" [disabled]="!(deviceSession?.isConnected)">
            <mat-option value="camera">Camera</mat-option>
            <mat-option value="screen">Screen</mat-option>
            <mat-option value="terminal">Terminal</mat-option>
        </mat-select>
    </mat-form-field>

    <p class="control_label">Enable Audio</p>
    <mat-slide-toggle color="warn" [(ngModel)]="viewSelection.audioStreamEnabled" [disabled]="!(deviceSession?.isConnected)"></mat-slide-toggle>

    <button  mat-raised-button color="warn"  class="ml-16" (click)="restartRemoteControlApp()" [disabled]="isRestartingConnection" >
        Restart Connection
    </button>

</ng-template>
