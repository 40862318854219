<div app-looma-page pageTitle="Performance Reports" pageIcon="show_chart" [headerSideTpl]="headerSideTpl">
    <looma-grid [dataSource]="dataSource">
        <ng-template rowExpansionTemplate let-data="data">
            <div>
                <table>
                    <thead>
                    <th>Awareness</th>
                    <th>Discovery</th>
                    <th>Conversion</th>
                    <th>Loyalty</th>
                    <th>Overlap</th>
                    <th>Full Funnel Value</th>
                    <th>Roas Multiplier</th>
                    <th>Number Of Stores</th>
                    <th>Cost</th>
                    <th>Discount</th>
                    <th>Discounted Roas Multiplier</th>
                    <th>Regions</th>
                    </thead>
                    <tr>
                        <td>
                            {{ data.performanceData.awarenessValue | currency:'USD' }}
                        </td>
                        <td>
                            {{ data.performanceData.discoveryValue | currency:'USD' }}
                        </td>
                        <td>
                            {{ data.performanceData.conversionValue | currency:'USD' }}
                        </td>
                        <td>
                            <span
                                *ngIf="isCampaignDateMoreThan90Days(data.brandPromoCampaign.promoPeriod)">{{ getLoyaltyValue(data) | currency:'USD' }}</span>
                            <span *ngIf="!isCampaignDateMoreThan90Days(data.brandPromoCampaign.promoPeriod)" style="color: #9D2235">Coming soon</span>
                        </td>
                        <td>
                            {{ data.performanceData.overlapValue | currency:'USD' }}
                        </td>
                        <td>
                            {{ data.performanceData.fullFunnelValue | currency:'USD' }}
                        < /td>
                        <td>
                            {{ data.performanceData.roasMultiplier }}
                        </td>
                        <td>
                            {{ data.performanceData.numberOfStores }}
                        </td>
                        <td>
                            {{ data.performanceData.campaignCost | currency:'USD' }}
                        </td>
                        <td>
                            {{ data.performanceData.campaignDiscount | currency:'USD' }}
                        </td>
                        <td>
                            {{ data.performanceData.roasMultiplierDiscount }}
                        </td>
                        <td>
                            {{ data.performanceData.campaignRegions }}
                        </td>
                    </tr>
                </table>

                <div>
                    <p style="font-weight: 600; font-size: 16px">Emails</p>
                    <ul *ngIf="data.emailNotificationSentTo">
                        <li *ngFor="let x of data.emailNotificationSentTo">{{ x.email }}
                            - {{ x.sentAt | date: "MM/dd/yyyy HH:mm" }}
                        </li>
                    </ul>
                    <div *ngIf="!data.emailNotificationSentTo || data.emailNotificationSentTo.length==0"
                         style="margin-bottom: 10px">No email
                        notifications sent
                    </div>
                </div>
            </div>
        </ng-template>

        <ng-template gridColumn="status" let-data="data">
            <div *ngIf="data.cprStatus">
                <span class="status-label gray-label" *ngIf="!data.isVisible()">Hidden</span>

                <span *ngIf="data.isVisible()" class="status-label" [ngClass]="{
      'green-label': data.cprStatus === 'auto_approved' || data.cprStatus === 'reviewed',
      'orange-label': data.cprStatus === 'requires_review'
    }">
      {{ data.cprStatus === 'auto_approved' ? 'Auto Approved' : (data.cprStatus === 'reviewed' ? 'Reviewed' : 'Requires Review') }}
    </span>
            </div>
        </ng-template>


        <ng-template gridColumn="campaignName" let-data="data">
            <a [href]="getReportUrl(data)" target="_blank" *ngIf="data.cpr30Url()">
                {{ data.brandPromoCampaign.name }}
            </a>
        </ng-template>


        <ng-template gridColumn="emailNotificationSentTo" let-data="data">
            <div></div>
            <ul>
                <li *ngFor="let x of data.emailNotificationSentTo">{{ x }}</li>
            </ul>
        </ng-template>

        <ng-template gridColumn="cost" let-data="data">
            {{ data.performanceData.campaignCost | currency: 'USD' }}
        </ng-template>

        <ng-template gridColumn="sales" let-data="data">
            {{ data.performanceData.conversionSalesValue | currency: 'USD' }}
        </ng-template>

        <ng-template gridColumn="inc_sales" let-data="data">
            {{ data.performanceData.conversionValue | currency: 'USD' }}
        </ng-template>

        <ng-template gridColumn="rr-roas" let-data="data">
            {{ data.performanceData.fullFunnelValue | currency: 'USD' }}
        </ng-template>

        <ng-template gridColumn="lastUpdate" let-data="data">
            {{ data.updatedAt | date: "M/dd/yyyy HH:mm" }}
        </ng-template>

        <ng-template gridColumn="viewCount" let-data="data">
            {{ data.viewCount }}
        </ng-template>

        <ng-template gridColumn="actions" let-data="data">
            <button mat-icon-button [matMenuTriggerFor]="menu">
                <mat-icon>more_vert</mat-icon>
            </button>
            <mat-menu #menu="matMenu">
                <button mat-menu-item [disabled]="data.cprStatus != 'requires_review'" (click)="approve(data)">
                    <mat-icon>check_circle</mat-icon>
                    <span>Approve</span>
                </button>
                <button mat-menu-item (click)="refresh(data)">
                    <mat-icon>autorenew</mat-icon>
                    <span>Refresh</span>
                </button>
                <button mat-menu-item [disabled]="data.cprStatus == 'requires_review'"
                        (click)="sendEmailNotification(data)">
                    <mat-icon>email</mat-icon>
                    <span>Send Email</span>
                </button>
                <button mat-menu-item (click)="onShareUrl(data)">
                    <mat-icon>share</mat-icon>
                    <span>Share URL</span>
                </button>
            </mat-menu>
        </ng-template>
    </looma-grid>

    <ng-template #headerSideTpl>
        <form [formGroup]="form">
            <div fxLayout="row" fxLayoutGap="16px" fxLayoutAlign="start center">
                <mat-form-field style="width: 230px; color: white">
                    <mat-date-range-input [rangePicker]="picker">
                        <input matStartDate formControlName="start" placeholder="Start date">
                        <input matEndDate formControlName="end" placeholder="End date">
                    </mat-date-range-input>
                    <mat-hint>MM/DD/YYYY – MM/DD/YYYY</mat-hint>
                    <mat-datepicker-toggle matIconSuffix [for]="picker"></mat-datepicker-toggle>
                    <mat-date-range-picker #picker></mat-date-range-picker>
                </mat-form-field>

                <mat-form-field>
                    <mat-label>Retailers</mat-label>
                    <mat-select multiple formControlName="retailers">
                        <mat-option *ngFor="let r of allRetailers" [value]="r.id">{{ r.retailer_name }}</mat-option>
                    </mat-select>
                </mat-form-field>

                <mat-form-field>
                    <mat-label>Programs</mat-label>
                    <mat-select multiple formControlName="programs">
                        <mat-option *ngFor="let r of availablePrograms" [value]="r.id">{{ r.name }}</mat-option>
                    </mat-select>
                </mat-form-field>


                <app-autocomplete-search-field showClear="true" placeholder="Parent Company"
                                               searchObjectType="ParentBrand"
                                               (valueChanged)="onParentCompanyFilterChanged($event)"
                                               selectionMode="single"
                                               style="width: 200px;"></app-autocomplete-search-field>

                <app-autocomplete-search-field showClear="true" placeholder="Featured Brand"
                                               searchObjectType="BrandPartner"
                                               (valueChanged)="onFeatureBrandFilterChanged($event)"
                                               selectionMode="single"
                                               style="width: 200px;"></app-autocomplete-search-field>

                <div (click)="doCsvExport()" class="export-button">
                    <mat-icon class="export-icon">download</mat-icon>
                </div>
            </div>
        </form>
    </ng-template>
</div>
